<div
  class="p-8 pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="userMenuComponentService.shouldShowInternalLinks$ | async"
  (click)="userMenuComponentService.call()"
>
  <i class="fal fa-copy mr-8"></i>
  <p class="small">Appeler</p>
</div>
<div
  class="p-8 pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="userMenuComponentService.shouldShowInternalLinks$ | async"
  (click)="userMenuComponentService.sendSms()"
>
  <i class="fal fa-comments-alt mr-8"></i>
  <p class="small">SMS</p>
</div>
<div
  class="p-8 pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="userMenuComponentService.shouldShowInternalLinks$ | async"
  [dougsCopyToClipboard]="(companyStateService.activeCompany$ | async)?.diaCode"
>
  <i class="fal fa-copy mr-8"></i>
  <p class="small">{{ (companyStateService.activeCompany$ | async)?.diaCode }}</p>
</div>
<div
  class="p-8 pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="userMenuComponentService.shouldShowInternalLinks$ | async"
  (click)="userMenuComponentService.openServicesModal()"
>
  <i class="fal fa-book mr-8"></i>
  <p class="small">Prestations</p>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="(userStateService.activeUser$ | async)?.isEligibleToComptastart"
>
  <a class="p-8" rel="noopener" href="https://webapp.legalstart.fr/compte/mes-dossiers/#workflows" target="_blank">
    <i class="fal fa-external-link mr-8"></i>
    <p class="small">Legalstart</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="(userStateService.loggedInUser$ | async)?.isAdmin"
>
  <a
    class="p-8"
    rel="noopener"
    [href]="legacyApiServerUrl + '/users/' + (userStateService.activeUserIdChanged$ | async)?.id"
    target="_blank"
  >
    <i class="fal fa-external-link mr-8"></i>
    <p class="small">User</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="(userStateService.loggedInUser$ | async)?.isAdmin"
>
  <a
    class="p-8"
    rel="noopener"
    [href]="legacyApiServerUrl + '/companies/' + (companyStateService.activeCompany$ | async)?.id"
    target="_blank"
  >
    <i class="fal fa-external-link mr-8"></i>
    <p class="small">Company</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="userMenuComponentService.shouldShowInternalLinks$ | async"
>
  <a
    class="p-8"
    rel="noopener"
    [href]="legacyApiServerUrl + '/users/' + (userStateService.activeUserIdChanged$ | async)?.id + '/hubspot'"
    target="_blank"
  >
    <i class="fal fa-external-link mr-8"></i>
    <p class="small">Hubspot</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="userMenuComponentService.canManageStripe$ | async"
>
  <a
    class="p-8"
    rel="noopener"
    [href]="legacyApiServerUrl + '/companies/' + (companyStateService.activeCompany$ | async)?.id + '/stripe'"
    target="_blank"
  >
    <i class="fal fa-external-link mr-8"></i>
    <p class="small">Stripe</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="userMenuComponentService.shouldShowInternalLinks$ | async"
>
  <a
    class="p-8"
    rel="noopener"
    [href]="legacyApiServerUrl + '/users/' + (userStateService.activeUserIdChanged$ | async)?.id + '/intercom'"
    target="_blank"
  >
    <i class="fal fa-external-link mr-8"></i>
    <p class="small">Intercom</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin"
>
  <a
    class="p-8"
    rel="noopener"
    [href]="legacyApiServerUrl + '/companies/' + (companyStateService.activeCompany$ | async)?.id + '/pappers'"
    target="_blank"
  >
    <i class="fal fa-external-link mr-8"></i>
    <p class="small">Pappers</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="(userStateService.loggedInUser$ | async)?.isAdmin"
>
  <a
    class="p-8"
    rel="noopener"
    [href]="
      legacyApiServerUrl + '/companies/' + (companyStateService.activeCompany$ | async)?.id + '/actions/export-data'
    "
    target="_blank"
  >
    <i class="fal fa-external-link mr-8"></i>
    <p class="small">Exporter le dossier</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="(userStateService.loggedInUser$ | async)?.isAdmin"
>
  <a
    class="p-8"
    rel="noopener"
    [href]="
      'https://app.datadoghq.eu/apm/traces?query=env%3Aproduction%20%40req.params.companyId%3A$' +
      (companyStateService.activeCompany$ | async)?.id +
      '&agg_m=count&agg_m_source=base&agg_t=count&cols=core_service%2Ccore_resource_name%2Clog_duration%2Clog_http.method%2Clog_http.status_code&fromUser=false&historicalData=true&messageDisplay=inline&query_translation_version=v0&sort=desc&spanType=all&storage=hot&view=spans&start=1738814267541&end=1738828667541&paused=false'
    "
    target="_blank"
  >
    <i class="fal fa-external-link mr-8"></i>
    <p class="small">Datadog</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="
    (userStateService.loggedInUser$ | async)?.isAccountantOrAdmin &&
    (userStateService.loggedInUser$ | async)?.flags.includes('role:developper')
  "
>
  <a class="p-8" [routerLink]="URL.RPG | createUrl | async">
    <i class="fal fa-swords mr-8"></i>
    <p class="small">Tales Of Guard</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="
    (userStateService.loggedInUser$ | async)?.isAccountantOrAdmin &&
    (userStateService.loggedInUser$ | async)?.flags.includes('role:developper')
  "
>
  <a class="p-8" [routerLink]="URL.ADMIN | createUrl | async">
    <i class="fal fa-gears mr-8"></i>
    <p class="small">Infrastructure</p>
  </a>
</div>
<div
  class="pointer user-menu-dropdown__item user-menu-dropdown__item--admin"
  *ngIf="(companyStateService.activeCompany$ | async)?.ownerId !== (userStateService.loggedInUser$ | async)?.id"
>
  <a class="p-8" [routerLink]="URL.ME | replaceActiveCompanyIdInUrl | async">
    <dougs-avatar
      *ngIf="userStateService.loggedInUser$ | async as loggedInUser"
      [image]="loggedInUser.profile?.avatarUrl || loggedInUser.profile?.alternateAvatarUrl"
      size="tiny"
      class="mr-8"
    />
    <p class="small">Revenir à moi</p>
  </a>
</div>
