@if (userMenuComponentService.refreshCompanies$ | async) {}
@if (userMenuComponentService.refreshOtherCompanies$ | async) {}
<div
  dougsClickOutside
  (clickOutside)="userMenuComponentService.closeUserMenu()"
  [ngClass]="{
    'is-compact': isCompact
  }"
>
  <div
    class="pointer user-menu"
    [ngClass]="{
      'user-menu--opened': userMenuComponentService.userMenuOpened$(),
      'p-4 m-4 is-compact': isCompact,
      'p-8 m-8': !isCompact
    }"
    (click)="userMenuComponentService.toggleUserMenu()"
    [dougsTooltip]="
      isCompact
        ? ((companyStateService.activeCompany$ | async)?.legalName ||
            (companyStateService.activeCompany$ | async)?.brandName) +
          ' - ' +
          (userStateService.activeUserFullName$ | async)
        : ''
    "
  >
    <div class="user-menu__avatar-name">
      <div class="user-menu__avatar-name__avatar">
        <dougs-avatar
          *ngIf="userStateService.activeUser$ | async as activeUser"
          class="user-menu__avatar-name__avatar"
          [ngClass]="{ 'mr-8': !isCompact }"
          [image]="activeUser.profile?.avatarUrl || activeUser.profile?.alternateAvatarUrl"
        ></dougs-avatar>
        <div
          *ngIf="presenceMemberStateService.presenceMembers$ | activeUserConnected | async"
          class="user-menu__avatar-name__avatar__connected"
        ></div>
      </div>
      <div class="user-menu__avatar-name__name mr-8" *ngIf="!isCompact">
        <div
          class="user-menu__avatar-name__name--legal-name mb-4"
          *ngIf="(companyStateService.activeCompany$ | async)?.legalName"
        >
          {{ (companyStateService.activeCompany$ | async)?.legalName }}
        </div>
        <div
          class="user-menu__avatar-name__name--username"
          [ngClass]="{
            'user-menu__avatar-name__name--username--bold': !(companyStateService.activeCompany$ | async)?.legalName
          }"
        >
          {{ (userStateService.activeUserFullName$ | async) || (userStateService.activeUser$ | async).email }}
        </div>
      </div>
    </div>
    <i class="fal fa-chevron-right"></i>
  </div>
  <dougs-user-menu-dropdown *ngIf="userMenuComponentService.userMenuOpened$()" [isCompact]="isCompact" />
</div>
